@import "./mixins";

// Colors
@import "./colors";
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  font-family: "Inter", serif !important;
  background: #828282;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.pageLoader {
  width: 50px;
  margin: 0 auto;
}
img.loaderlogo {
  max-width: 50px;
}
input:focus-visible {
  outline: none !important;
}
select:focus-visible {
  outline: none !important;
}
.input-set{
  .form-control {
    width: 100%;
    border-radius: 12px;
    background: #e5cfbd;
    color: #000 !important;
    border: 0px;
  }
  .flag-dropdown {
    border-radius: 12px 0px 0px 12px;
    background: #e5cfbd;
    border: 0px;
  }
}

////////////############ Auth css ############////////////

.mainauth {
  background-image: url('/assets/img/bg-img.png');
  background-color: #095575;
}
.otp_div input {
  width: 42px !important;
  height: 42px !important;
  border-radius: 8px;
  background: #E7E7E7;
}
.otp_div div {
  gap: 0px 8px;
  justify-content: center;
}

////////////############ Auth css end ############////////////


////////////############ Profile css ############////////////

.bg_profile {
  background-image: url('/assets/img/profile_bg.png');
}

////////////############ Profile css end ############////////////


.rounded_div {
  position: relative;
}
.rounded_div:before {
  content: '';
  position: absolute;
  top: -55px;
  right: 0px;
  width: 70px;
  height: 55px;
  background: transparent;
  border-radius: 50% 0px;
  box-shadow: 10px 10px 0 #828282;
}
.rounded_div:after {
  content: "";
  position: absolute;
  bottom: -55px;
  right: 0px;
  width: 70px;
  height: 55px;
  background: transparent;
  border-radius: 0px 50%;
  box-shadow: 10px -10px 0 #828282;
}
.paginationdiv li.page {
  background: none;
  border: none;
  box-shadow: none;
}
.paginationdiv ul {
  display: flex;
  align-items: center;
  gap: 0px 6px;
}
.paginationdiv ul li {
  margin-right: 0px !important;
}

///////////////########## active-sidebar ###########//////////////////

.active-sidebar .min_sidbar {
  padding-left: 0px !important;
}
.active-sidebar .min_sidbar a {
  margin: 0px;
  margin-left: 15px;
  width: 100%;
}
.active-sidebar .rounded_div:before {
  content: "";
  position: absolute;
  top: -55px;
  right: 15px;
  width: 44px;
  height: 55px;
  background: transparent;
  border-radius: 50% 0px;
  box-shadow: 10px 10px 0 #828282;
}
.active-sidebar .rounded_div:after {
  content: "";
  position: absolute;
  bottom: -55px;
  right: 15px;
  width: 44px;
  height: 55px;
  background: transparent;
  border-radius: 0px 50%;
  box-shadow: 10px -10px 0 #828282;
}

 .active-sidebar .sidebar-brand a {
  margin-left: 0px !important;
  display: flex;
  justify-content: center;
}
 .active-sidebar .sidebar-brand img {
  width: 50px;
}
select:focus option:checked {
  background: #FEE4D0;
}

@media (max-width: 1199px) {
  .hide_show {
    display: none;
  }
  .active-sidebar .hide_show {
    display: block !important;
  }
}

@media (max-width: 639px) {

}

///////////////########## active-sidebar end ###########//////////////////

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbcbc;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #969595;
  border-radius: 30px;
}

.set-bg-images{
  background-image: url('../../public/assets/img/bg-profile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  // height: 170px;
}
.slick-prev, .slick-next {
  color: #000000 !important;
}

.chat-bg-image{
  background-image: url('../../public/assets/img/chat-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  // height: calc(100vh - 200px);
}



//slick//
.slick-slider.slick-initialized {
  position: relative;
} 
svg.slick-arrow.slick-prev {
  position: absolute;
  left: 2px;
  top: 50%;
  z-index: 10;
  color: #e1ae84 !important;
  bottom: 50%;
  background: #9797978f;
  border-radius: 50px;
  width: 25px;
  height: 25px;
}
svg.slick-arrow.slick-next {
  position: absolute;
  right: 2px;
  top: 50%;
  z-index: 10;
  color: #e1ae84 !important;
  bottom: 50%;
  width: 25px;
  height: 25px;
  background: #9797978f;
border-radius: 50px;
}



::-webkit-scrollbar {
    width: 3px;
}
 
::-webkit-scrollbar-track {
    background-color: #fff !important;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #FEE4D0 !important; 

}